// @flow strict
import React from 'react';
import Author from './Author';
import Contacts from './Contacts';
import Copyright from './Copyright';
import Menu from './Menu';
import styles from './Sidebar.module.scss';
import { useSiteMetadata } from '../../hooks';
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons";


const Sidebar = ({ isIndex }: Props) => {
  const { author, copyright, menu } = useSiteMetadata();
  return (
    <nav className={styles['sidebar']}>
    <div className={styles['sidebar__inner_desktop']}>
      <Author author={author} isIndex={isIndex} />
      <Menu menu={menu} />
      <Contacts contacts={author.contacts} />
      <Copyright copyright={copyright} />
      </div>
      <div className={styles['sidebar__inner_mobile']}>
      <Author author={author} isIndex={isIndex} />
      <Menu menu={menu} />
      <Contacts contacts={author.contacts} />
    </div>
    </nav>
  );
};

export default Sidebar;
