import React from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import styles from './Consent.module.scss';

type Props = {
  title: string,
  message: string
};

const Consent = ({ title, message }: Props) => {
  return (
    <CookieConsent
    buttonWrapperClasses={styles['consent__button']}
    buttonClasses={styles['consent__button-btn']}
    containerClasses={styles['consent__banner']}
    contentClasses={styles['consent__message']}
    sameSite='strict'
    cookieName='_consent'
    cookieValue='{"necessary":true, "analytics":true}'
    buttonText='Yeah Sure!'
    disableButtonStyles={true}
    onAccept={({ acceptedByScrolling }) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'cookieConsent',
        consent: {
          necessary: true,
          analytics: true
        }
      });
  }}
    >
    <h2 className={styles['consent__message-title']}>{title || "This website uses cookies"}</h2>
    <p className={styles['consent__message-content']}>{message ||"Not the evil kind, there's no advertisers, just anonymous analytics so I know what content people like (or if anyone reads it at all!). If you don't want them, just ignore this message."}</p>
    </CookieConsent>
);
}

export default Consent;
